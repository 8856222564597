<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-card-body class="p-0">
              <!-- Biaya -->
              <b-form-group label="Biaya" label-for="cost">
                <vue-numeric
                  id="cost"
                  v-model="model.cost"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Masukkan Biaya"
                  currency="Rp"
                  separator="."
                ></vue-numeric>
              </b-form-group>

              <!-- Uraian -->
              <b-form-group label="Uraian" label-for="note">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Uraian"
                  rules="required"
                >
                  <b-form-input
                    id="note"
                    v-model="model.note"
                    placeholder="Masukkan uraian..."
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- Upload Kwitansi -->
              <b-form-group label="Upload Kwitansi" label-for="inputKwitansi">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Kwitansi"
                  :rules="metaAction === 'edit' ? '' : `required`"
                >
                  <b-form-file
                    id="inputKwitansi"
                    v-model="kwitansi"
                    :placeholder="placeholderFile"
                    drop-placeholder="Taruh file disini..."
                    accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                    @change="handleUploadKwitansi"
                  ></b-form-file>
                  <b-link
                    v-if="currentReceiptUrl"
                    :href="currentReceiptUrl"
                    target="_blank"
                    class="d-block text-info mt-1"
                  >
                    {{ currentReceiptUrl.split('/').at(-1) }}
                  </b-link>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-card-body>
            <b-card-footer align="right" class="px-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="goBack"
              >
                Kembali
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="loadingUpload"
                class="ml-1"
                variant="primary"
                type="submit"
              >
                Simpan
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  name: 'SppdAccommodationForm',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BLink,
  },
  directives: { Ripple },
  data() {
    const { sppdId, sppdAccommodationId } = this.$route.params
    const { action: metaAction } = this.$route.meta

    return {
      sppdId,
      sppdAccommodationId,
      metaAction,
      config: {
        api: '/sppd/accomondation',
        redirect: `/biaya-akomodasi/${sppdId}`,
      },
      model: {
        cost: 0,
        note: '',
        document: '',
      },
      currentReceiptUrl: null,
      kwitansi: null,
      placeholderFile: 'Pilih file...',
      loadingUpload: false,
    }
  },
  mounted() {
    if (this.metaAction === 'edit') {
      this.getAccommodation()
    }
  },
  methods: {
    numbersOnly(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    handleUploadKwitansi(event) {
      this.loadingUpload = true
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('folder', this.$route.query.folder)
      formData.append('type', 'akomodasi')

      axios
        .post('/misc/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.loadingUpload = false
          this.model.document = res.data.filename
        })
        .catch(() => {
          this.loadingUpload = false
          // console.error(err.response)
        })
    },
    save() {
      const { note, cost, document } = this.model
      const data = {
        sppd_id: this.sppdId,
        note,
        total_cost: cost,
        document,
      }
      if (this.metaAction === 'store') {
        axios
          .post(`${this.config.api}`, data)
          .then(() => {
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Biaya akomodasi berhasil ditambahkan',
              },
            })
          })
          .catch(() => {
            // console.error(e.message)
          })
      } else {
        axios
          .put(`${this.config.api}/${this.sppdAccommodationId}`, data)
          .then(() => {
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Biaya akomodasi berhasil diubah',
              },
            })
          })
          .catch(() => {
            // console.error(e.message)
          })
      }
    },
    goBack({ query }) {
      this.$router.push({
        path: this.config.redirect,
        query,
      })
    },
    getAccommodation() {
      axios.get(`${this.config.api}/${this.sppdAccommodationId}`).then(res => {
        const { total_cost: cost, note, document, file } = res.data.data

        this.currentReceiptUrl = file

        this.model = { cost, note, document: document.split('/').at(-1) }
      })
    },
  },
}
</script>

<style>
.kwitansi-filename {
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
}
</style>
